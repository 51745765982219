/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, {
    useEffect, useRef, useState,
} from 'react';
import {
    any, object, func, string,
} from 'prop-types';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';

let eventLogic;
const ConversationalUIChatBot = ({
    deviceType, brand, browser, operatingSystem, brandTheme, pageType,
}) => {
    const iframeRef = useRef(null);
    const pageTypeRef = useRef(pageType);
    const [isIframeMaximized, setIsIframeMaximized] = useState(false);
    const isMobile = deviceType !== 'desktop';
    const [isIframeLoaded, setIsIframeLoaded] = useState(true);
    const trustedOrigin = `${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}`;

    useEffect(() => {
        pageTypeRef.current = pageType;
    }, [pageType]);

    useEffect(() => {
        if (isIframeMaximized) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isIframeMaximized]);

    useEffect(() => {
        const handleMessage = (event) => {
            try {
                if (event.data.type === 'pwa-events-logic') {
                    const functionBody = JSON.parse(event.data.data);
                    // eslint-disable-next-line no-new-func
                    eventLogic = new Function('event', 'brand', 'browser', 'deviceType', 'operatingSystem', 'brandTheme', 'trustedOrigin', 'setIsIframeMaximized', 'iframeRef', 'pageTypeRef', 'isMobile', functionBody);
                }
                if (eventLogic) {
                    eventLogic(event, brand, browser, deviceType, operatingSystem, brandTheme, trustedOrigin, setIsIframeMaximized, iframeRef, pageTypeRef, isMobile);
                }
            } catch (error) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    errorMessage: error,
                    message: `FloraChat: Could not post value - ERROR: ${error}`,
                });
            }
        };
        if (isIframeLoaded) {
            window?.addEventListener('message', handleMessage);
        }
        return () => {
            window?.removeEventListener('message', handleMessage);
        };
    }, [isIframeLoaded, trustedOrigin]);

    const handleIframeLoad = () => setIsIframeLoaded(true);
    const handleIframeError = () => setIsIframeLoaded(false);

    return isIframeLoaded ? (
        <iframe
            id="18fchatbot"
            ref={iframeRef}
            title="18fchatbot"
            className="test"
            allowFullScreen
            style={{
                overflow: 'hidden',
                boxShadow: 'none',
                border: 'none',
                position: 'fixed',
                zIndex: 2243000,
                bottom: '10px',
                right: '0%',
                width: '80px',
                height: '80px',
            }}
            src={`${trustedOrigin}/chat/flora`}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
        />
    ) : null;
};

ConversationalUIChatBot.propTypes = {
    deviceType: string.isRequired,
    brand: object.isRequired,
    browser: func.isRequired,
    operatingSystem: func.isRequired,
    brandTheme: any.isRequired,
    pageType: string.isRequired,
};

export default ConversationalUIChatBot;
